/* eslint-disable @typescript-eslint/no-explicit-any */
import { animated, useSpring } from '@react-spring/web';
import { kv } from '@vercel/kv';
import isEmpty from 'lodash/isEmpty';
import type { NextPageContext } from 'next';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCountryStaticProps,
  getCountryTopNamesStaticProps,
} from 'src/actions/countryActions';
import { searchForAllCountriesStaticProps } from 'src/actions/searchActions';
import ErrorIcon from 'src/assets/ErrorIcon';

import { PageLayout } from 'src/components/layout/pageLayout.component';
import TheThreeW from 'src/components/the-three-w/the-three-w';

import WorldChart from 'src/components/world-chart/world-chart';
import { worldDomainName } from 'src/config';
import { countryActions } from 'src/reducers/countryReducer';
import { homepageActions } from 'src/reducers/homepageReducer';
import type { RootState } from 'src/store';
import type { OptionTypes } from 'src/types/actionSheetTypes';
import type { DataWorldChartTypes } from 'src/types/types';
import type { WorldData, WorldDataTypes } from 'src/types/world';
import { allCountries as allCountriesJson } from 'src/utils/allCountries';
import { allCountriesPopulation as allCountriesPopulationJson } from 'src/utils/allCountriesPopulation';
import { getCookie, setCookie } from 'src/utils/cookies';
import { displayToast } from 'src/utils/displayToast';
import getPassedSeconds from 'src/utils/getPassedSeconds';
import { continents, regions } from 'src/utils/regions';
import RenderLayout, { Platform } from '../components/core/render-layout';
import RecordedPopulation from '../components/world-chart/recorded-population';
import { actions as searchActions } from '../reducers/searchReducer';
import { cn } from '../utils/cn';

export type HomeProps = {
  countries: DataWorldChartTypes[] | { message: string }[];
  mostRecordedCountriesFromProps: DataWorldChartTypes[] &
    { name: any; record: number }[];
  WORdata?: WorldData;
  allCountriesData: any;
};

export default function Home({
  countries,
  mostRecordedCountriesFromProps,
  WORdata: WorDataFromProps,
  allCountriesData,
}: Readonly<HomeProps>) {
  const router = useRouter();

  const dispatch = useDispatch();

  const { isZoomIn, isZoomOut, regionFromRedux, country, isDrawerOpen } =
    useSelector((state: RootState) => state.search);
  const { countryData } = useSelector((state: RootState) => state.country);
  const [WORdata, setWORdata] = useState<WorldDataTypes>();

  const [mostRecordedCountries, setMostRecordedCountries] = useState(
    mostRecordedCountriesFromProps
  );

  const {
    option: reduxOption,
    animatePopUp,
    ionutVariant,
    dorianVariant,
  } = useSelector((state: RootState) => state.homepage);

  const secondTour = getCookie('secondTour');

  const [populationCountToDisplay, setPopulationCountToDisplay] =
    useState<number>(0);

  const [countryPopulationToDisplay, setCountryPopulationToDisplay] =
    useState<number>(0);

  useEffect(() => {
    if (
      (countries as { message: string }[])?.[0]?.message === 'Error from server'
    ) {
      router.push({
        pathname: '/404',
      });
      displayToast(
        (countries as { message: string }[])?.[0]?.message,
        'error',
        <ErrorIcon />
      );
    }

    if (countries?.length === 0) {
      displayToast('Internal Server Error', 'error', <ErrorIcon />);
    }
  }, []);

  const countriesFromFreeRESTAPI = useMemo(
    () =>
      allCountriesJson?.map((country) => {
        return {
          name: country?.name,
          iso_2: country?.['alpha-2'],
          iso_3: country?.['alpha-3'],
          population: allCountriesPopulationJson?.find(
            (countryPopulation) => countryPopulation?.name === country?.name
          )?.population,
        };
      }),
    []
  );

  useEffect(() => {
    if (ionutVariant) {
      dispatch(homepageActions?.setAnimatePopUp(true));
      setTimeout(() => {
        dispatch(homepageActions?.setAnimatePopUp(false));
      }, 3500);
      setTimeout(() => {
        setCookie('secondTour', 'false', 60 * 60 * 24);
      }, 5000);
    }
  }, [ionutVariant]);

  const firstPoppingAnimation = useSpring({
    from: {
      scale: !animatePopUp || !secondTour ? 1 : 0.8,
      opacity: !animatePopUp || !secondTour ? 1 : 0,
    },
    to: {
      scale: 1,
      opacity: 1,
    },
    config: {
      mass: 1,
      frequency: 0.4,
    },
    delay: 1200,
  });

  const secondPoppingAnimation = useSpring({
    from: {
      scale: !animatePopUp || !secondTour ? 1 : 0.8,
      opacity: !animatePopUp || !secondTour ? 1 : 0,
    },
    to: {
      scale: 1,
      opacity: 1,
    },
    config: {
      mass: 1,
      frequency: 0.4,
    },
    delay: 2200,
  });

  const { t, i18n } = useTranslation([
    'common',
    'new-homepage',
    'new-navbar',
    'new-footer',
    'homepage',
  ]);

  const zoomIn = () => {
    const foundSubcontinent = regions?.filter((subcontinent) => {
      return (
        subcontinent?.subcontinent?.toLowerCase() ===
        country?.continent?.toLowerCase()
      );
    });

    const foundContinent = continents?.filter((continent) => {
      return (
        continent?.continent?.toLowerCase() ===
        country?.continent?.toLowerCase()
      );
    });
    let subContinentOrContinent: string | null;

    if (country?.continent === 'Europe') {
      subContinentOrContinent = foundContinent?.[0]?.region;
    } else if (country?.iso_2 === 'AU') {
      subContinentOrContinent = '009';
    } else {
      subContinentOrContinent = foundSubcontinent?.[0]?.region;
    }

    if (regionFromRedux === 'world' || regionFromRedux === null) {
      dispatch(
        searchActions.setRegionFromRedux({
          regionFromRedux: subContinentOrContinent,
        })
      );
    } else {
      dispatch(
        searchActions.setRegionFromRedux({ regionFromRedux: country?.iso_2 })
      );
    }
  };

  const zoomOut = () => {
    const foundSubcontinent = regions?.filter((subcontinent) => {
      return subcontinent?.subcontinent === country?.continent;
    });

    const foundContinent = continents?.filter((continent) => {
      return continent?.continent === country?.continent;
    });

    let subContinentOrContinent: string | null;

    if (country?.continent === 'Europe') {
      subContinentOrContinent = foundContinent?.[0]?.region;
    } else if (country?.iso_2 === 'AU') {
      subContinentOrContinent = '009';
    } else {
      subContinentOrContinent = foundSubcontinent?.[0]?.region;
    }

    if (regionFromRedux === subContinentOrContinent) {
      dispatch(searchActions.setRegionFromRedux({ regionFromRedux: 'world' }));
    }
    if (
      regionFromRedux !== subContinentOrContinent &&
      regionFromRedux !== 'world' &&
      regionFromRedux !== null
    ) {
      dispatch(
        searchActions.setRegionFromRedux({
          regionFromRedux: subContinentOrContinent,
        })
      );
    }
  };

  const openSearchBar = () => {
    setCookie('secondTour', 'false', 60 * 60 * 24);
    dispatch(
      searchActions.setIsMegaMenuOpen({
        isMegaMenuOpen: true,
      })
    );
  };

  const [isOpenSearchFromClickToFindOut, setIsOpenSearchFromClickToFindOut] =
    useState<boolean>(false);

  const openSearchFromClickToFindOut = () => {
    setIsOpenSearchFromClickToFindOut(true);
  };

  const zoomOptions = ({ className }: { className?: string } = {}) => {
    return (
      <div
        className={cn(
          'human mr-[67px] flex items-center justify-end justify-items-end',
          className
        )}
      >
        {isZoomOut && (
          <button
            onClick={zoomOut}
            className="mr-2 mt-[-120px] size-[25px] rounded bg-[#D9D9D9] text-black hover:bg-[#D9D9D950]"
          >
            <p className="text-center">-</p>
          </button>
        )}
        <p className="inter mr-2 mt-[-120px] text-[14px]">Zoom</p>
        {isZoomIn && (
          <button
            onClick={zoomIn}
            className="mt-[-120px] size-[25px] rounded bg-[#D9D9D9] text-black hover:bg-[#D9D9D950]"
          >
            <p className="text-center">+</p>
          </button>
        )}
      </div>
    );
  };

  useEffect(() => {
    const lastRun = WorDataFromProps?.statistics?.stats_run?.last_run;
    const population = WorDataFromProps?.living || 0;
    const pending = WorDataFromProps?.pending || 0;

    dispatch(
      countryActions.setLastRun({
        lastRun: countryData?.statistics?.stats_run?.last_run,
      })
    );
    const incrementValuePopulation =
      pending == 0 ? 0.2 : Math.round(pending / 86400);
    setPopulationCountToDisplay(
      population + getPassedSeconds(lastRun) * incrementValuePopulation > 10
        ? 10
        : incrementValuePopulation
    );
    const incrementValueCountry =
      countryData?.population?.pending == 0
        ? 0.2
        : Math.round(countryData?.population?.pending / 86400);

    setCountryPopulationToDisplay(
      countryData?.population?.living +
        getPassedSeconds(countryData?.statistics?.stats_run?.last_run) *
          incrementValueCountry >
        10
        ? 10
        : incrementValueCountry
    );
  }, [countryData]);

  const [option, setOption] = useState<OptionTypes>('stats');

  const handleOption =
    (option: OptionTypes) => (e?: { preventDefault: () => void }) => {
      if (e) {
        e?.preventDefault();
      }

      setOption(option);
    };

  useEffect(() => {
    dispatch(searchActions.setRegionFromRedux({ regionFromRedux: 'world' }));
    dispatch(
      searchActions.setIsZoomIn({
        isZoomIn: false,
      })
    );
    dispatch(
      searchActions.setIsZoomOut({
        isZoomOut: false,
      })
    );
    handleOption(reduxOption)();
  }, [reduxOption]);

  return (
    <div className="homepage min-h-full min-w-full">
      <PageLayout
        fullWidth
        title={t('homepage:seo.title')}
        description={t('homepage:seo.description')}
        countries={countries as DataWorldChartTypes[]}
      >
        <div className="flex min-h-full flex-col md:block md:min-h-0">
          <div className="snap-scroll-element-2">
            <RenderLayout platforms={[Platform.DESKTOP]}>
              <div
                className={`mb-[10px] flex flex-col items-center justify-center ${
                  isDrawerOpen ? 'ml-[300px]' : ''
                }`}
              >
                <p className="whitespace-pre-wrap text-[55px] font-semibold">
                  <RecordedPopulation WORdata={WorDataFromProps} />
                </p>
                {!country?.record_count && !isEmpty(country) && (
                  <RenderLayout platforms={[Platform.DESKTOP, Platform.TABLET]}>
                    <p className="h-[24.5px]"></p>
                  </RenderLayout>
                )}
                <p
                  data-tip=""
                  data-for="are-you-one-of-them"
                  id="are-you-one-of-them"
                  className="text-[16px]"
                >
                  {WorDataFromProps?.living === 0
                    ? null
                    : isEmpty(country)
                    ? t('humans-listed').toUpperCase()
                    : `${country?.ID ? 'in' + ' ' + country?.name : ''}`}
                </p>
              </div>
            </RenderLayout>
            {(isZoomIn || isZoomOut) &&
              zoomOptions({ className: 'max-xl:hidden' })}
            <WorldChart
              WORdata={WorDataFromProps}
              countriesFromFreeRESTAPI={countriesFromFreeRESTAPI}
              mostRecordedCountries={mostRecordedCountriesFromProps}
              countries={countries as DataWorldChartTypes[]}
              allCountriesData={allCountriesData}
              population={
                !isEmpty(country)
                  ? countryPopulationToDisplay
                  : populationCountToDisplay
              }
              homepage={true}
              option={option}
            />
          </div>

          <RenderLayout
            platforms={
              option === 'info' || reduxOption === 'info'
                ? [Platform.DESKTOP, Platform.TABLET, Platform.MOBILE]
                : [Platform.DESKTOP, Platform.TABLET]
            }
          >
            <TheThreeW />
          </RenderLayout>
        </div>
      </PageLayout>
    </div>
  );
}

export const getServerSideProps = async ({ locale }: NextPageContext) => {
  let countries: DataWorldChartTypes[] = [];
  let WORdata, arrayOfMostRecordedCountries;
  const allCountriesData: any[] = [];
  const fifteenMinutes = 15 * 60 * 1000; // 15 minutes in milliseconds
  const now = Date.now();
  const translations = await serverSideTranslations(locale ?? 'en', [
    'common',
    'navbar',
    'new-homepage',
    'new-navbar',
    'new-footer',
    'homepage',
  ]);
  try {
    // Get cached data and timestamp from KV
    const cachedCountries = await kv.get('countries');
    const cachedWORdata = await kv.get('WORdata');
    const cachedTimestamp = await kv.get('cachedTimestamp');
    const cachedMostRecordedCountries = await kv.get(
      'mostRecordedCountriesFromProps'
    );
    const cachedAllCountriesData = await kv.get('allCountriesData');

    // If cache exists and is still valid (not older than 15 minutes), use the cached data
    if (
      cachedCountries &&
      cachedWORdata &&
      cachedMostRecordedCountries &&
      cachedTimestamp &&
      now - Number(cachedTimestamp) < fifteenMinutes
    ) {
      countries = cachedCountries as DataWorldChartTypes[];
      WORdata = cachedWORdata;
      arrayOfMostRecordedCountries = cachedMostRecordedCountries;
      allCountriesData.push(...(cachedAllCountriesData as any));
    } else {
      // Fetch new data if cache is missing or stale
      [countries, WORdata] = await Promise.all([
        searchForAllCountriesStaticProps(),
        getCountryStaticProps('WOR', worldDomainName), // Replace with the actual domain name
      ]);

      const promises = countries.map((country) =>
        getCountryStaticProps(
          country.iso_3,
          country.api_public_domain_name
        ).then((result) => ({
          ...result,
          iso_3: country.iso_3, // Attach iso_3 code
          name: country.name, // Attach country name
        }))
      );

      const promisesForNames = countries.map((country) =>
        getCountryTopNamesStaticProps(
          country?.iso_3,
          country?.api_public_domain_name,
          '1',
          '100'
        ).then((result) => ({
          ...result,
          iso_3: country.iso_3, // Attach iso_3 code to match with country data
        }))
      );

      arrayOfMostRecordedCountries = await Promise.allSettled(promises).then(
        (results) => {
          const valuesFromPromises: {
            name: string;
            record: number;
            population: number;
          }[] = [];

          return Promise.allSettled(promisesForNames).then((namesResults) => {
            results.forEach((result, index) => {
              if (result.status === 'fulfilled') {
                const value = result.value;
                const matchingNames = namesResults.find(
                  (namesResult) =>
                    namesResult.status === 'fulfilled' &&
                    namesResult.value.iso_3 === value.iso_3
                );

                const combinedData = {
                  ...value,
                  ...((matchingNames as { value: { data: [] } })?.value?.data ||
                    {}),
                };

                allCountriesData.push(combinedData);

                valuesFromPromises.push({
                  name: combinedData.name,
                  record: parseFloat(
                    (
                      ((combinedData?.population?.living || 0) /
                        (countries[index]?.population || 1)) *
                      100
                    ).toFixed(2)
                  ),
                  population: combinedData?.population?.living || 0,
                });

                const cacheKey = `country-data-${combinedData?.name
                  ?.replace(' ', '-')
                  ?.toLocaleLowerCase()}-${locale ?? 'en'}`;
                kv.set(cacheKey, JSON.stringify(combinedData)); // Cache the combined result
              }
            });

            return valuesFromPromises.sort((a, b) => b.record - a.record);
          });
        }
      );

      // Save the fetched and processed data to KV along with the current timestamp
      await Promise.all([
        kv.set('countries', JSON.stringify(countries)),
        kv.set('allCountriesData', JSON.stringify(allCountriesData ?? [])),
        kv.set(
          'mostRecordedCountriesFromProps',
          JSON.stringify(arrayOfMostRecordedCountries)
        ),
        kv.set(
          'WORdata',
          JSON.stringify({
            ...WORdata,
            living: WORdata?.population?.living || null,
            iso3: 'WOR',
            last_run: WORdata?.statistics?.stats_run?.last_run || null,
            pending: WORdata?.population?.pending || null,
          })
        ),
        kv.set('cachedTimestamp', now.toString()), // Save the current timestamp
      ]);
    }
  } catch (error) {
    console.error(error);
  }

  return {
    props: {
      ...(translations && typeof translations === 'object' ? translations : {}),
      countries: countries ?? [],
      allCountriesData: allCountriesData as any,
      mostRecordedCountriesFromProps: arrayOfMostRecordedCountries ?? [],
      WORdata: {
        ...WORdata,
        living: WORdata?.population?.living || null,
        iso3: 'WOR',
        last_run: WORdata?.statistics?.stats_run?.last_run || null,
        pending: WORdata?.population?.pending || null,
      },
    },
  };
};
