import Link from 'next/link';
import React, { useMemo } from 'react';

interface SecondaryButtonProps {
  basicTextClassName?: string;
  textClassName: string;
  basicButtonClassName?: string;
  extraButtonClassName?: string;
  children?: JSX.Element;
  text?: string;
  width?: number;
  height?: number;
  disabled?: boolean;
  onClick?: (
    e?: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>
  ) => void;
  widthFull?: boolean;
  href?: string;
  spinner?: JSX.Element | boolean;
  leftIcon?: boolean;
}

export const SecondaryButton = ({
  textClassName,
  basicTextClassName = 'text-[#257C6B] leading-[20px] font-[600]',
  basicButtonClassName = 'flex justify-center disabled:opacity-[0.3] focus:shadow-[0px_0px_0px_4px_rgba(154,220,225,0.7)] hover:shadow-lg hover:bg-[#F2FFFC] transition items-center rounded-[5px] bg-white border-[2px] border-[#257C6B]',
  extraButtonClassName = '',
  children,
  width,
  height,
  disabled = false,
  spinner = undefined,
  text,
  onClick,
  widthFull = false,
  leftIcon = false,
  href,
}: SecondaryButtonProps) => {
  const props = useMemo(() => {
    return {
      disabled,
      onClick,
      className: `${basicButtonClassName} ${extraButtonClassName} ${
        widthFull ? 'w-full' : width ? `w-[${width}px]` : '' // todo this needs to be replaced, it will be purged on build
      } ${height ? `h-[${height}px]` : ''}`, // todo this needs to be replaced, it will be purged on build. Replace with explicit Tailwind classname, such as h-[44px] when using this component
    };
  }, [
    basicButtonClassName,
    disabled,
    extraButtonClassName,
    height,
    href,
    onClick,
    width,
    widthFull,
  ]);

  if (href) {
    return (
      <Link prefetch={false} href={href} {...props}>
        {leftIcon ? children : null}
        <p className={`${basicTextClassName} ${textClassName}`}>{text}</p>
        {!leftIcon ? children : null}
      </Link>
    );
  }

  return (
    <button {...props}>
      {!spinner ? (
        <>
          {leftIcon ? children : null}
          <p className={`${basicTextClassName} ${textClassName}`}>{text}</p>
          {!leftIcon ? children : null}
        </>
      ) : (
        <>{spinner}</>
      )}
    </button>
  );
};
